export function getEnumValue(enumObj: { [s: string]: string }, value: string) {
    return enumObj[value as keyof typeof enumObj];
}

export function loadFileIntoImgSrc(elementId: string, file: File) {
    var fr = new FileReader();
    fr.onload = function () {
        let element = document?.getElementById(elementId)
        console.log(`Element: ${elementId}`)
        if (element) element.setAttribute('src', fr.result as any)
    }
    fr.readAsDataURL(file);
}

export function loadFileIntoImgsSrc(elementIds: string[], file: File) {
    console.log(`Uploading file: ${file}`)
    for (let elementId of elementIds){
        loadFileIntoImgSrc(elementId, file)
    }
}

export function loadPathIntoImgSrc(elementId: string, path: string) {
    let element = document?.getElementById(elementId)
    if (element) element.setAttribute('src', path)
}

export function loadPathIntoImgsSrc(elementIds: string[], path: string) {
    for (let elementId of elementIds){
        loadPathIntoImgSrc(elementId, path)
    }
}
