import { useEffect, useState } from "react";
import { Uploader } from "rsuite";
import "./_add-static-files.scss";
import { FileType } from "rsuite/esm/Uploader";

interface IProps {
  onSetEditStatic: () => void;
  onUploads: (file: File) => void;
}

export default function StaticModal({ onSetEditStatic, onUploads }: IProps) {
  const handleClose = (event: React.MouseEvent) => {
    // Закриваємо модалку, тільки якщо клік був здійснений на задній фон
    if (event.target === event.currentTarget) {
      onSetEditStatic();
    }
  };

  const uploadStatic = (file: any) => {
    console.log(file);
    if (file) {
      onSetEditStatic();
      onUploads(file);
    }
  };

  return (
    <div className="drag_modal_wrap" onClick={handleClose}>
      <div className="drag_modal" onClick={(e) => e.stopPropagation()}>
        <Uploader
          action=""
          autoUpload={false}
          multiple={false}
          onChange={uploadStatic}
          draggable
          onRemove={(file) => {
            console.log(`Файл видалено: ${file.name}`);
            // Тут можна видалити файл з IndexedDB, якщо потрібно
          }}
        >
          <div
            style={{
              height: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              Натисніть або перетягніть файли в цю область, щоб завантажити
            </span>
          </div>
        </Uploader>
      </div>
    </div>
  );
}
