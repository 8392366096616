import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  SelectPicker,
  Stack,
} from "rsuite";
import { TOffer, TPartner } from "types/offers-temporary";
import BlockIcon from "@rsuite/icons/Block";
import FileUpload from "@rsuite/icons/FileUpload";
import Trash from "@rsuite/icons/Trash";
import countryList from "country-list";
import "./_offers-crud.scss";
import {
  Events,
  FileUploadWithPreview,
  ImageAddedEvent,
} from "file-upload-with-preview";
import "file-upload-with-preview/dist/style.css";

import {
  loadFileIntoImgSrc,
  loadFileIntoImgsSrc,
  loadPathIntoImgSrc,
  loadPathIntoImgsSrc,
} from "utils/general";
const bgUpload = new FileUploadWithPreview("offer-bg-upload");
const fgUpload = new FileUploadWithPreview("offer-fg-upload");

interface ItemDataType<T = number | string> extends Record<string, any> {
  label?: string | React.ReactNode;
  value?: T;
  groupBy?: string;
  parent?: ItemDataType<T>;
  children?: ItemDataType<T>[];
  loading?: boolean;
}

interface IOfferCrudParams {
  offer?: TOffer;
  offers: TOffer[];
  partners: TPartner[];
  onClose: () => void;
}

export default (params: IOfferCrudParams) => {
  let { offers, partners, onClose } = params;

  let [_, setTime] = useState(Date.now());

  let update = () => {
    setTime(Date.now());
  };

  let [hasChanged, setHasChanged] = useState(false);
  let [offer, setOffer] = useState<Partial<TOffer>>(params.offer || {});

  // FIXME huyna
  let bg = `http://localhost:4000/images/${offer.graphicFolder}/bg.png`;
  let fg = `http://localhost:4000/images/${offer.graphicFolder}/logo.png`;

  let [countryListMapped] = useState(
    countryList
      .getData()
      .map((data) => ({ label: data.name, value: data.code }))
  );

  let [partnersMapped] = useState(
    partners.map((item, index) => ({ label: item.name, value: index }))
  );

  let [id, setId] = useState(params.offer?.id || null);
  let [partnerIndex, setPartnerIndex] = useState(
    params.offer
      ? partners.findIndex((p) => p.partnerId == offer?.partnerId)
      : null
  );
  let [geo, setGeo] = useState(params.offer?.geo || null);
  let [link, setLink] = useState(params.offer?.link || null);
  let [idUnlocked, setIdUnlocked] = useState(false);
  let [nameUnlocked, setNameUnlocked] = useState(false);

  let updateOfferImages = () => {
    if (fgUpload.cachedFileArray.length > 0) {
      loadFileIntoImgsSrc(
        ["offer-fg", "offer-preview-fg"],
        fgUpload.cachedFileArray[fgUpload.cachedFileArray.length - 1]
      );
    } else {
      loadPathIntoImgsSrc(["offer-fg", "offer-preview-fg"], fg);
    }

    if (bgUpload.cachedFileArray.length > 0) {
      loadFileIntoImgsSrc(
        ["offer-bg", "offer-preview-bg"],
        bgUpload.cachedFileArray[bgUpload.cachedFileArray.length - 1]
      );
    } else {
      loadPathIntoImgsSrc(["offer-bg", "offer-preview-bg"], bg);
    }
  };

  useEffect(() => {
    if (!id) {
      let id = 0;
      for (let offer of offers) {
        if (offer.id > id) id = offer.id;
      }

      setId(id + 1);
    }

    updateOfferImages();

    window.addEventListener(Events.IMAGE_ADDED, (e: Event) => {
      const { detail } = e as unknown as ImageAddedEvent;

      updateOfferImages();

      update();
    });

    while (bgUpload.cachedFileArray.length > 0) {
      bgUpload.deleteFileAtIndex(0);
    }

    while (fgUpload.cachedFileArray.length > 0) {
      fgUpload.deleteFileAtIndex(0);
    }
  }, []);

  let onCancel = () => {
    if (hasChanged) {
      // TODO show alert
    }

    onClose();
  };

  let onConfirm = () => {
    onClose();
  };

  let onFgUploadClick = () => {
    if (fgUpload.cachedFileArray.length > 0) {
      while (fgUpload.cachedFileArray.length > 0) {
        fgUpload.deleteFileAtIndex(0);
      }

      updateOfferImages();
      update();
    } else fgUpload.emulateInputSelection();
  };

  let onBgUploadClick = () => {
    if (bgUpload.cachedFileArray.length > 0) {
      console.log(bgUpload.cachedFileArray);
      while (bgUpload.cachedFileArray.length > 0) {
        bgUpload.deleteFileAtIndex(0);
      }
      console.log(bgUpload.cachedFileArray);

      updateOfferImages();
      update();
    } else bgUpload.emulateInputSelection();
  };

  let idElement = (
    <InputNumber className="input" id="id" disabled defaultValue={id || ""} />
  );

  let nameElement = (
    <InputGroup inside className="nameGroup">
      {nameUnlocked ? (
        <Input
          className="input"
          id="name"
          value={offer?.name}
          onChange={(value) => setOffer({ ...offer, name: value })}
        />
      ) : (
        <Input
          className="input"
          id="name"
          disabled
          defaultValue={offer?.name}
        />
      )}
      {offer && !nameUnlocked && (
        <InputGroup.Button
          onClick={() => {
            // TODO alert
            setNameUnlocked(true);
          }}
        >
          <BlockIcon />
        </InputGroup.Button>
      )}
    </InputGroup>
  );

  let captionElement = (
    <Input className="input" id="caption" defaultValue={offer?.caption} />
  );
  let partnerElement = (
    <SelectPicker
      className="input"
      cleanable={false}
      style={{ width: "100%" }}
      value={partnerIndex}
      onChange={setPartnerIndex}
      data={partnersMapped}
    />
  );
  let geoElement = (
    <SelectPicker
      className="input"
      searchBy={(keyword: string, label: ReactNode, item: ItemDataType) => {
        if (!label) return false;

        let countryCode = countryList.getCode(label.toString());

        return (
          countryCode == keyword ||
          label.toString().toLowerCase().startsWith(keyword.toLowerCase())
        );
      }}
      cleanable={false}
      value={geo}
      onChange={setGeo}
      data={countryListMapped}
      style={{ width: "100%" }}
    />
  );
  let linkElement = (
    <Input className="input" id="link" defaultValue={offer?.link} />
  );

  return (
    <Modal
      backdrop={hasChanged ? "static" : false}
      size="lg"
      open={true}
      onClose={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          {offer ? `Редагування оферу #${offer.id}` : `Заведення оферу`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="pictures-vertical-stack">
            <div className="bg" onClick={onBgUploadClick}>
              <img id="offer-bg" className="offer-bg" />
              <div className="offer-bg-overlay"></div>
              {bgUpload.cachedFileArray.length > 0 ? (
                <span className="bg-remove">
                  <Trash />
                </span>
              ) : (
                <span className="bg-upload">
                  <FileUpload />
                </span>
              )}
            </div>
            <span className="plus noselect">+</span>
            <div className="fg" onClick={onFgUploadClick}>
              <div className="offer-fg-overlay"></div>
              <img id="offer-fg" className="offer-fg" />
              {fgUpload.cachedFileArray.length > 0 ? (
                <span className="fg-remove">
                  <Trash />
                </span>
              ) : (
                <span className="fg-upload">
                  <FileUpload />
                </span>
              )}
            </div>
          </div>
          <span className="arrow noselect">➟</span>
          <div className="offer-preview refined-shadow">
            <img
              className="offer-background"
              crossOrigin="anonymous"
              id="offer-preview-bg"
            ></img>

            <a className="item-link">
              <div className="offer-image-container">
                <img id="offer-preview-fg" className="offer-foreground"></img>
              </div>
              <span className="refined-shadow offer-play-button noselect">
                Play
              </span>
            </a>
          </div>
          <Stack
            className="content"
            spacing={7}
            direction="column"
            alignItems="stretch"
          >
            <Form.ControlLabel>ID</Form.ControlLabel>
            {idElement}
            <Form.ControlLabel>Назва</Form.ControlLabel>
            {captionElement}
            <Form.ControlLabel>Ключ</Form.ControlLabel>
            {nameElement}
            <Form.ControlLabel>Партнерка</Form.ControlLabel>
            {partnerElement}
            <Form.ControlLabel>Гео</Form.ControlLabel>
            {geoElement}
            <Form.ControlLabel>Посилання</Form.ControlLabel>
            {linkElement}
          </Stack>
        </div>
        {/* TODO замочок біля поля імені оферу */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} appearance="subtle">
          Відміна
        </Button>
        <Button active={hasChanged} onClick={onConfirm} appearance="primary">
          {offer ? "Зберегти" : "Створити"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
