import { useEffect, useRef, useState } from "react";
import { Table, Badge, Checkbox } from "rsuite";
import adminService from "../../../../network/panel/network";
import { IDomain, DomainStatus } from "@bprotsyk/aso-core";
import React from "react";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import ReloadIcon from "@rsuite/icons/Reload";
import "./_table.scss";

const LOCAL_STORAGE_ARCHIVED_DOMAIN = "domains-for-archived";

const { Column, HeaderCell, Cell } = Table;

type DomainSortKeys = keyof IDomain | "";
type SortType = "asc" | "desc" | undefined;
interface FilterState {
  status: string[];
  archived: string[];
  target: string[];
}

interface IHandleEditingDomain {
  onHandleEditingDomain: (e: any) => void;
  searchQuery: string;
  filterSelectors: FilterState;
  onToggleButtonShow: (e: boolean) => void;
}

interface IDomainsArchived {
  id: string;
  name: string;
}

// status color
const getStatusIndicator = (status: DomainStatus) => {
  switch (status) {
    case DomainStatus.PENDING:
      return "#ffeb3b";
    case DomainStatus.READY:
    case DomainStatus.READY_AND_NOTIFIED:
      return "#4caf50";
    default:
      return "#9e9e9e";
  }
};
const NOTE_COLOR = "#ffc73d";
const RELOAD_COLOR = "#34c3ff";

const ContainerTable = React.memo(
  ({
    onHandleEditingDomain,
    searchQuery,
    filterSelectors,
    onToggleButtonShow,
  }: IHandleEditingDomain) => {
    const [sortColumn, setSortColumn] = useState<DomainSortKeys>("");
    const [data, setData] = useState<IDomain[]>([]);
    const [sortType, setSortType] = useState<SortType>(undefined);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkableMap, setCheckableMap] = useState<IDomainsArchived[]>([]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      adminService.getDomains().then((res) => setData(res));
    }, []);

    // custom date display
    const timestampToDaysWithHandlingOneDay = (milliseconds: number) => {
      const days = Math.round(milliseconds / (1000 * 60 * 60 * 24));
      if (days === 0) {
        return `Сьогодні`;
      } else if (days % 10 == 1) {
        return `1 день`;
      } else if (days % 10 > 1 && days % 10 < 5) {
        return `${days} дні`;
      } else if (days < 0) {
        return `Сплив`;
      } else {
        return `${days} днів`;
      }
    };

    // table sort
    const getData = () => {
      // Фільтрація даних за фільтрами
      let filteredData = data; // Використовуємо копію data

      if (
        filterSelectors?.status?.length > 0 ||
        filterSelectors?.archived?.length > 0 ||
        filterSelectors?.target?.length > 0
      ) {
        filteredData = data.filter((item) => {
          const statusMatch =
            filterSelectors?.status?.length > 0
              ? filterSelectors?.status.includes(item.status)
              : true;

          const archivedMatch =
            filterSelectors?.archived?.length > 0
              ? filterSelectors?.archived.includes(
                  item.archived ? "archived" : "notArchived"
                )
              : true;

          const targetMatch =
            filterSelectors.target?.length > 0
              ? filterSelectors.target.includes(item.target)
              : true;

          return statusMatch && archivedMatch && targetMatch;
        });
      }

      if (!sortColumn || !sortType) {
        return filteredData;
      }

      return [...filteredData]?.sort((a, b) => {
        let x: any = a[sortColumn as keyof IDomain];
        let y: any = b[sortColumn as keyof IDomain];

        if (sortColumn === "createdAt" || sortColumn === "expiresAt") {
          x = new Date(a[sortColumn]);
          y = new Date(b[sortColumn]);
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    };


    const filterData = () => {
      if (!searchQuery || searchQuery.length === 0) {
        return getData();
      }

      return data?.filter((domain) => {
        return (
          domain?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          domain?.ip?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
      });
    };

    const handleSortColumn = (sortColumn: any, sortType: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
      }, 500);
    };

    // table size
    const tableSize = Math.min(window.innerWidth) - 20;
    // cell size, given that the first column is 100 pixels
    const cellSize = (tableSize - 100) / 7;

    const checkboxChanges = (domain: any): void => {
      console.log(domain);
      setCheckableMap((prevDomains) => {
        const domainExists = prevDomains.some((d) => d.id === domain.id);

        let updatedDomains;
        if (domainExists) {
          updatedDomains = prevDomains.filter((d) => d.id !== domain.id);
          sessionStorage.setItem(
            LOCAL_STORAGE_ARCHIVED_DOMAIN,
            JSON.stringify(updatedDomains)
          );
        } else {
          updatedDomains = [
            ...prevDomains,
            { id: domain.id, name: domain.name },
          ];
          sessionStorage.setItem(
            LOCAL_STORAGE_ARCHIVED_DOMAIN,
            JSON.stringify(updatedDomains)
          );
        }

        setChecked(updatedDomains.length > 0);
        return updatedDomains;
      });
    };

    useEffect(() => {
      onToggleButtonShow(checked);
    }, [checked]);

    const handleMouseOver = () => {
      setIsVisible(true);
    };

    const handleMouseOut = () => {
      setIsVisible(false);
    };

    return (
      <div>
        {isVisible && (
          <div className="note_modal">
            <p className="text">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Laudantium obcaecati incidunt delectus doloremque cumque commodi
              natus rerum, soluta sint, unde, fuga consequatur. Blanditiis nobis
              minima neque accusantium ipsa praesentium ad.
            </p>
            <span className="sub_text">
              редагування приміток доступне в налаштуваннях хоста
            </span>
          </div>
        )}

        {data && (
          <Table
            data={filterData()}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            width={tableSize}
            className="table"
          >
            <Column align="center" fixed sortable>
              <HeaderCell>Статус</HeaderCell>
              <Cell dataKey="status">
                {(rowData) => (
                  <Badge
                    style={{
                      background: getStatusIndicator(rowData.status),
                    }}
                  />
                )}
              </Cell>
            </Column>

            <Column align="left" fixed sortable width={cellSize}>
              <HeaderCell>Назва</HeaderCell>
              <Cell dataKey="name">
                {(rowData) => {
                  return <p>name</p>;
                }}
              </Cell>
            </Column>

            <Column width={cellSize}>
              <HeaderCell>
                <Checkbox
                  color="blue"
                  checked={checked}
                  onChange={() => {
                    setChecked(false);
                    setCheckableMap([]);
                  }}
                ></Checkbox>
              </HeaderCell>
              <Cell dataKey="arhived">
                {(rowData) => {
                  return (
                    <div className="arhived_custom_container">
                      <Checkbox
                        color="blue"
                        onChange={() => checkboxChanges(rowData)}
                        checked={
                          checkableMap.find(
                            (domain) => domain.id === rowData.id
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                  );
                }}
              </Cell>
            </Column>

            <Column width={cellSize} fixed sortable>
              <HeaderCell>Хост</HeaderCell>
              <Cell dataKey="name">
                {(rowData) => (
                  <div className="host-cel">
                    <p className="text">{rowData.name}</p>
                  </div>
                )}
              </Cell>
            </Column>

            <Column width={cellSize} sortable>
              <HeaderCell>Зпливає</HeaderCell>
              <Cell dataKey="expiresAt">
                {(rowData) => (
                  <div className="date-cell">
                    {timestampToDaysWithHandlingOneDay(
                      rowData.expiresAt - Date.now()
                    )}
                  </div>
                )}
              </Cell>
            </Column>

            <Column width={cellSize} sortable>
              <HeaderCell>A @</HeaderCell>
              <Cell dataKey="ip">
                {(rowData) => (
                  <p
                    className="custom_text"
                    onClick={() => onHandleEditingDomain(rowData)}
                  >
                    {rowData.ip}
                  </p>
                )}
              </Cell>
            </Column>

            <Column width={cellSize} sortable>
              <HeaderCell>Авто-оновлення</HeaderCell>
              <Cell dataKey="note">
                {(rowData: IDomain) => {
                  if (rowData) {
                    return <div>{<ReloadIcon fill={RELOAD_COLOR} />}</div>;
                  }
                }}
              </Cell>
            </Column>

            <Column width={cellSize} sortable>
              <HeaderCell>примітки</HeaderCell>
              <Cell dataKey="note">
                {(rowData: IDomain) => {
                  // if (rowData?.note) {
                  return (
                    <div
                      className="note_icon"
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {<InfoRoundIcon fill={NOTE_COLOR} />}
                    </div>
                  );
                  // }
                }}
              </Cell>
            </Column>
          </Table>
        )}
      </div>
    );
  }
);

export default ContainerTable;
