import React from "react";
import { Dropdown, Nav, Navbar } from "rsuite";
import "./Navbar.scss";
import logo from "assets/logo.png";
import { IoIosArrowBack, IoIosHome } from "react-icons/io";

export enum NavbarTab {
  INDEX,
  CONSTRUCTOR_FLASH,
  AUTH,
  OFFERS,
  DOMAINS,
}

interface AppHubNavbarProps {
  tab: NavbarTab;
  // config: DefaultConfig,
  // configs: DefaultConfig[],
  // onConfigChange: (config: DefaultConfig) => void
}

export default (props: AppHubNavbarProps) => {
  let { tab } = props;

  // let dropdownItems: any[] = []
  // configs.forEach((c) => {
  //   if (c.id == config?.id) return

  //   dropdownItems.push(
  //     <Dropdown.Item eventKey={c?.id}>#{c?.id} <code>{c?.bundle}</code></Dropdown.Item>
  //   )
  // })

  return (
    <Navbar>
      <Navbar.Brand className="brand">
        <img src={logo} className="logo" />
      </Navbar.Brand>
      <Nav activeKey={tab.toString()}>
        {/* <Nav.Item href="/" icon={<IoIosHome />} eventKey={NavbarTab.DASHBOARD.toString()}>
          Дашборд
        </Nav.Item> */}
        <Nav.Item href="offers" eventKey={NavbarTab.OFFERS.toString()}>
          Офери
        </Nav.Item>
        <Nav.Item href="/" eventKey={NavbarTab.DOMAINS.toString()}>
          Домени
        </Nav.Item>
        {/* <Nav.Item href="admin" eventKey={NavbarTab.ADMIN.toString()}>Admin</Nav.Item> */}
        {/* <Nav.Item href="logs" eventKey={NavbarTab.LOGS.toString()}>Логи</Nav.Item> */}
      </Nav>

      {/* // picker appok prosto ohuenniy realno topoviy */}
      {/* <Nav pullRight>
        <Dropdown className="app-picker" placement='bottomEnd' onSelect={(key: string) => {
          let config = configs.find((c) => c.id == parseInt(key))
          if (config) onConfigChange(config)
        }} trigger="click" title={<span><b className="app-id">#{config?.id}</b> <code>{config?.bundle}</code></span>}>
          {dropdownItems}
        </Dropdown>
      </Nav> */}
    </Navbar>
  );
};
