import { IDomain } from "@bprotsyk/aso-core";
import { useState } from "react";
import { Button, Modal, Stack } from "rsuite";
import "./_archive-modal.scss";
const LOCAL_STORAGE_ARCHIVED_DOMAIN = "domains-for-archived";

interface IDomainsArchived {
  id: string;
  name: string;
}
interface IProps {
  onClose: () => void;
}

function ArchivedDomainsModal({ onClose }: IProps) {
  const [data, setData] = useState<IDomainsArchived[]>(() => {
    const localData = sessionStorage.getItem(LOCAL_STORAGE_ARCHIVED_DOMAIN);
    if (localData) {
      return JSON.parse(localData);
    }
    return [];
  });

  console.log(data);

  return (
    <Modal backdrop={"static"} size="sm" open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title className="flex_title">Архівування Доменів</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Stack
            className="content"
            spacing={7}
            direction="column"
            alignItems="stretch"
          >
            <div className="domain_wrap_archived">
              <ul className="list">
                {data.map((item) => (
                  <li className="list_item">
                    <p className="name">{item.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          </Stack>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="blue"
          appearance="ghost"
          className="button-buy-domains"
          // onClick={toggleArchiveShowModal}
        >
          Архівувати
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ArchivedDomainsModal;
