import { useNavigate, Link, useLocation } from "react-router-dom";
import ModalForm from "./login-form/modal_template";
import authApi from "network/auth/network";
import useAuth from "hooks/useAuth";
interface IFormData {
  username: string;
  password: string;
}

export default function Login() {
  const { setAuth }: any = useAuth();
  const nav = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/offers";

  const handleSubmit = async (formData: IFormData) => {
    try {
      let response = await authApi.login(formData.username, formData.password)

      if (response) {
        localStorage.setItem("token", response.token);
        localStorage.setItem(
          "userRole",
          JSON.stringify(response.accessScopes)
        );
        setAuth(response.token);

        // что иначе?
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  nav(from, { replace: true });

  return <ModalForm onSubmited={handleSubmit} />;
}
