import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

export default function RequireAuth({ allowedRoles }: any) {
  const { auth }: any = useAuth();
  const { roles }: any = useAuth();
  const location = useLocation();
  console.log([allowedRoles, roles])
  return auth && roles.some((role: any) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  );
}
