import { IOffer } from "@bprotsyk/aso-core"

export interface TOffer {
    id: number,
    name: string,
    graphicFolder: string,
    geo: string,
    link: string,
    caption: string,
    partnerId: number,
    type: IOfferType,
    emojiIcon?: string,
    emojiCaption?: string,
    keitaroId: string | null,
    status: TOfferStatus,
    hidden?: boolean
}

export interface TPartner {
    partnerId: number,
    name: string
}

export enum TOfferStatus {
    DEFAULT = "default",
    READY = "ready",
    SETUP = "setup",
    ERROR = "error"
}

export enum IOfferType {
    GAMBLING = "gambling",
    BETTING = "betting",
    FIN = "fin",
}

export interface IPartnerSection {
    id: number,
    type: IOfferType,
    name: string,
    offers: IOffer[],
}

export const GroupOffersByQuery = "groupBy"
export enum GroupOffersBy {
    PLAIN = "plain",
    PARTNERS = "partners",
    COUNTRIES = "countries"
}

export const TypeToShow = "type"
export const ShowHiddenOffersQuery = "showHidden"

export interface IOffersAdminResponse {
    plain: TOffer[],
    byPartners?: { [key: number]: TOffer[] },
    byCountries?: { [key: string]: TOffer[] },
    partners: TPartner[]
}