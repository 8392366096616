import { createContext, useState } from "react";
const AuthContext = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<null | string>(null);
  const [roles, setRoles] = useState();
  const getToken = localStorage.getItem("token");
  const getRoles = localStorage.getItem("userRole");
  if (getToken && !auth) {
    setAuth(getToken);
  }
  if (getRoles && !roles) {
    setRoles(JSON.parse(getRoles).map((role: string) => parseInt(role)));
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth, roles, setRoles }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
