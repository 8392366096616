import axios from "axios";
const $API = axios.create({
        // TODO change base url to https://cg-main-server.com/
    baseURL: "https://cg-main-server.com",
    // baseURL: "http://localhost:4000/",
    headers: {
        "Content-type": "application/json",
    }

});
$API.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    config.headers!.Authorization = `Bearer ${token}`;;

    return config;
});
export default $API;
