import { useForm } from "react-hook-form";
import "./modal.scss";
type FormData = {
  username: string;
  password: string;
};
function CreateModal({ onSubmited }: any): JSX.Element {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit((data) => onSubmited(data));

  return (
    <div className="auth_form_container">
      <form className="auth_form" onSubmit={onSubmit}>
        <h3 className="auth_form_title">Sign In</h3>
        <input
          {...register("username")}
          className="form_control"
          placeholder="Enter name"
          required
        />
        <input
          {...register("password")}
          type="password"
          className="form_control "
          placeholder="Enter password"
          required
        />
        <button type="submit" className=" btn_submit">
          Submit
        </button>
      </form>
    </div>
  );
}
export default CreateModal;
